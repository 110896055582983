export default {
  zh: {
    done: '完成',
    loginOrRegister: '登入 / 註冊',
    next: '下一步',
    loginPhone: '您的手機號碼',
    finish: '完成',
    completeRegistration: '完成您的註冊',
    nickname: '您的姓名',
    loadingMore: '載入中...',

    deleteAccount: '刪除帳戶',
    confirmDeleteAccountMsg: '注意：確定刪除此帳戶及所有相關資料?',
    accountDeleted: '帳戶已刪除',

    待開工: '待開工',
    工程中: '工程中',
    已完工: '已完工',
    已取消: '已取消',
    details: '詳情',

    // Devices
    lastOnlineTime: '最後上線: ',
    lastUpdated: '最後更新：',
    'yellow': '黃色',
    'red': '紅色',
    'black': '黑色',

    // Check Location
    overview: '總覽',
    addNewEntity: '新增',
    updateEntity: '更改資料',
    target: '目標',
    confirmMove: '確認移動?',
    showPhotos: '顯示相片',
    showPhotoStats: '顯示相片統計',
    showBackgroundImg: '背景圖',
    hidePhotos: '隱藏相片',
    showPhotoCodeLabels: '顯示照片編號',

    // Camera Prompt
    photo: '相片',
    fromPhotos: '從相簿中選擇',
    takePicture: '拍照',
    successUpdatePhoto: '照片更新成功！',
    change: '更換',
    editInfo: '更改資料',
    complete: '完成',
    confirmLeave: '確認離開？',

    shop: '商城',
    cart: '購物車',
    checkout: '結帳',
    forum: '討論區',
    profile: '帳戶',
    services: '服務',

    sale: '特價',
    soldOut: '售罊',

    yes: '是',
    no: '否',
    back: '返回',
    confirm: '確定',
    cancel: '取消',
    all: '全部',

    remark: '備註',
    submit: '提交',

    buyNow: '立即購買',
    addToCart: '加入購物車',
    addToLikedItems: '加入我的清單',
    removeFromLikedItems: '從我的清單移除',
    enquiry: '聯繫客服',
    product: '產品',
    
    successUpdateLikedItems: '成功更新喜愛清單',
    successAddedToCart: '成功加到購物車',

    errorHeader: '錯誤！',

    // Booking
    pending: '待確認',
    confirmed: '已確認',
    ongoing: '進行中',
    completed: '已完成',
    cancelled: '已取消',
    minutes: '分鐘',
    bookingDetails: '預約詳情',
    bookedServices: '預約服務：',
    bookingQRCode: '預約 QR Code',
    changeBookingTime: '更改預約時間',
    cancelBooking: '取消預約',
    confirmCancel: '確認取消',
    confirmCancelBooking: '確定取消此預約？',
    bookingCancelled: '已取消預約。',
    newBooking: '新預約',
    timesPurchased: '購買總次數：',
    timesUsed: '已使用：',
    timesRemaining: '尚餘：',
    bookingDate: '預約日期',
    selectBookingDate: '請選擇預約日期',
    bookingTime: '預約時間',
    selectBookingTime: '請選擇預約時間',
    updateBooking: '更改預約',
    createBooking: '預約',
    successCreateBooking: '預約成功！',
    successUpdateBooking: '己成功更改預約。',
    beforeTreatment: '療程前',
    afterTreatment: '療程後',

    deliveryDistrict: '送貨地區：',
    deliveryAddress: '送貨地址：',
    customerName: '客戶姓名：',
    contactPhone: '聯絡電話：',
    customerEmail: '電郵地址：',

    CheckoutPage: {
      yourOrder: '您的訂單',
      orderRemark: '訂單備註',
      specialIntructions: '給賣家的特別指示',
      deliveryInfo: '送貨資料',
      selectDistrict: '請選擇送貨地區',
      enterDeliveryAddress: '請輸入送貨地址',
      enterCustomerName: '請輸入您的名字',
      enterContactPhone: '請輸入聯絡電話',
      enterCustomerEmail: '請輸入電郵地址',
      contactInfo: '聯絡資料',
      paymentInfo: '付款資料',
      confirmPayment: '確認付款',
      paymentProof: '付款證明'
    },
    CartPage: {
      lineTotal: '總計：',
      orderTotal: '商品總額：',
      checkout: '前往付款',
      shopNow: '即去購物',
      emptyCart: '購物車內沒有任何商品',
      removeCartItem: '刪除購物車商品',
      confirmRemoveCartItem: '確定移除此購物車商品？',
    },

    HomePage: {
      featuredProducts: '熱門推介',
      recommendedForYou: '推䔍給您',
      latestProducts: '最新上架',
    },

    OrderPage: {
      order: '訂單',
      orderStatus: '訂單狀態',
      orderItems: '訂單產品',
      lineTotal: '總計：',
      orderTotal: '商品總額：',
      deliveryInfo: '送貨資料',
      orderNumber: '訂單號碼：',
      total: '合共：',
    },

    PostPage: {
      newPost: '新帖子',
      lastUpdated: '最後更新：',
      numOfComments: '則留言',
      newComment: '新增留言',
      commentBtnText: '留言',
      editPost: '編輯帖子',
      postTitle: '帖子主題',
      postContent: '帖子內容',
      postImage: '圖片',
      updatePost: '更新帖子',
      publishPost: '發佈帖子',
      successPublishPost: '已成功發佈帖子。',
      successUpdatePost: '已成功更新帖子。',
      confirmDelete: '確認刪除',
      confirmDeletePost: '確認刪除此帖子？',
      deletePost: '刪除帖子',
      postDeleted: '已刪除帖子。',
    },

    ProductPage: {
      numOfCustomerReviews: '買家評論',
      newReview: '寫評論',
      successPublishProductReview: '已成功發佈商品評論。',
      reviewRating: '評分',
      reviewContent: '評論',
      publishReview: '發表評論',
    },

    ForgotPasswordPage: {
      loginEmail: '登入電郵',
      forgotPassword: '忘記密碼',
      sendResetPasswordEmail: '發送重設密碼電郵',
      resetPswEmailSentTo: '重設密碼電郵已發送至',
      checkEmailReset: '請檢查您的郵箱並透過電郵內的連結重設登入密碼。',
      backToLogin: '返回登入頁面',
      enterLoginEmail: '請輸入登入電郵。',
    },

    ThankYouPage: {
      thankYouForYourOrder: '感謝您的訂單！',
      description: '稍候您將會收到訂單確認通知。',
      backToHomePage: '返回主頁',
    },

    UserProfilePage: {
      changeProfilePhoto: '更換頭像',
      successChangeProfilePhoto: '個人頭像更換成功！',
      editPersonalInfo: '修改個人資料',
      changePassword: '更改密碼',
      oldPassword: '現有密碼',
      newPassword: '新密碼',
      newPasswordConfirm: '確認新密碼',
      inconsistentNewPasswordMsg: '兩次輸入的新密碼不一致，請重新輸入。',
      wrongOldPassword: '現有密碼輸入錯誤，請重新輸入。',
      successUpdatePassword: '成功更改密碼！',
      enterAllFields: '請輸入所有欄位。',
      myOrders: '訂單紀錄',
      myPosts: '發帖紀錄',
      likedItems: '喜愛產品',
      language: '語言',
      darkTheme: '深色主題',
      termsAndConditions: '隱私政策和責任及條款',
      feedback: '意見專區',
      logout: '登出',
      confirmLogout: '確定登出？',
      lastName: '姓氏',
      firstName: '名字',
      emailAddress: '電郵地址',
      phone: '手機號碼',
      save: '儲存',
      name: '姓名',
    },

    // add extra zh translations below

    BookingStatus: {
      pending: '待確認',
      visitConfirmed: '已確認上門視察時間',
      visited: '視察完成，待報價',
      quoted: '已報價',
      quoteConfirmed: '已確認報價單',
      workConfirmed: '已確認工程時間',
      ongoing: '工程中',
      workDone: '已完工',
      pendingPayment: '待付款',
      paid: '已付款',
      completed: '已完成',
      cancelled: '已取消',
    },

    submitted: '已提交',
    notSubmitted: '未提交',
    surveySubmitted: '問卷提交成功！',

    bookings: '工程預約',
    backToMyBookings: '返回我的預約',
    thankYouForYourPayment: '感謝您的付款！',
    thankYouPaymentDescription: '稍候您將會收到付款確認通知及收據。',
    unknownErrorOccurred: '發生不明錯誤，請再試一次。',
    deposit: '訂金',
    payDeposit: '繳付訂金',
    finalPayment: '尾數',
    payNow: '立即付款',
    customerNote: '客戶備註',
    showQuotation: '顯示報價單',
    bookingQuotation: '工程報價單',
    confirmedQuotationMsg: '已成功確認報價單，我們稍候會有專人聯絡閣下預約工程時間。',
    bookingReceipt: '工程收據',
    showReceipt: '顯示工程收據',
    signConfirmQuote: '請在格內簽署以確認報價單',
    signInBox: '請在格內簽署',
    resetSignature: '重設簽名',
    signInsideBlackBox: '請在黑色框格內簽名',

    delete: '刪除',
    functionOnlyAvailableOnMobileApp: '請下載「WINS」手機應用程式以使用此功能',
    enablePermissionInAppSettings: '此功能需要開啟相機，請在應用程式設定啟用相機權限。',

    or: '或',
    loginInformation: '登入帳號資料',
    apartmentInformation: '住宅單位資料',
    registeringAccount: '設定帳戶中...',
    successUpdatePersonalInfo: '已成功更新個人資料。',
    clashEmailAddress: '此電子郵件地址已被使用。',
    wrongPassword: '登入資料錯誤！請重新輸入。',
    editMobileNumber: '更改手機號碼',

    aboutBaily: '關於百力',
    orgChart: '公司架構',
    scopeOfServices: '服務範圍',
    relatedDocuments: '相關文件',
    contactUs: '聯絡我們',
    companyAddress: '地址：',
    companyEmail: '電郵：',
    companyPhone: '公司電話：',
    fax: '傳真：',
    專業資格及牌照: '專業資格及牌照',
    屋宇署註冊證書: '屋宇署註冊證書',
    電業承辦商註冊證書: '電業承辦商註冊證書',
    註冊消防裝置承辦商: '註冊消防裝置承辦商',
    ISO認證副本: 'ISO認證副本',
    其他相關人員證書及牌照: '其他相關人員證書及牌照',
    無涉及任何法律訴訟信件: '無涉及任何法律訴訟信件',
    銀行發出之存款證明文件: '銀行發出之存款證明文件',
    主要管理人員資歷及證書: '主要管理人員資歷及證書',
    推薦信: '推薦信',

    days: '日',
    remainingDays: '距離完工尚餘 ',

    scanLocationQRCode: '掃描地盤二維碼',
    scanQRCodeAddLocation: '掃描地盤二維碼以新增地點',
    scanningQRCode: '掃描 QR Code 中 ...',
    search: '搜尋',
    unknown: '未知',
    project: '工程項目',
    projects: '工程項目',
    notifications: '通知',
    projectNotifications: '工程項目通知',
    residentFeedback: '住戶意見',
    projectAddress: '地址：',
    projectFee: '工程金額：',
    projectStartDate: '動工日期：',
    projectEndDate: '預計完工日期：',
    projectStatus: {
      inProgress: '工程中',
      completed: '已完工',
    },
    subscribedNotifications: '已訂閱通知',
    receiveNotifications: '接收工程通知',
    stopReceivingNotifications: '停止接收工程通知',
    sendFeedback: '意見反映',
    photoOptional: '補充相片 (如有)',
    projectNotFound: '工程項目不存在！',

    latestProjects: '最新工程項目',
    inProgressProjects: '工程中項目',
    completedProjects: '已完工項目',
    beforeWorkPhotos: '工程前相片',
    afterWorkPhotos: '工程後相片',
    workPhotos: '工程相片',

    locationInputWarningMsg: '請確保所填樓層及單位正確無誤，避免接收錯誤通告。',
    newUserLocation: '請填寫居住資料',
    userLocation: '用戶地點',
    buildingOrLocation: '大廈 / 地點',
    floor: '樓層',
    unit: '單位',
    業主: '業主',
    住戶: '住戶',
    其他: '其他',
    save: '儲存',
    deleteUserLocation: '刪除地點',
    confirmDelete: '確認刪除',
    confirmDeleteUserLocation: '確認刪除此地點',
    successDeleteUserLocation: '已成功刪除地點。',
    successUpdateUserLocation: '已成功更新用戶居住地點。',
    selectBuildingOrLocation: '請選擇大廈 / 地點',
    addBuildingOrLocation: '新增大廈',
    myLocations: '我的地點',
    myLocation: '我的地點',
    involvingProjects: '我負責的項目',

    description: '描述',
    address: '地址',
    selectOrEnterAddress: '請選擇 / 輸入您的地址',

    clientPhotos: '客戶提供的相片',
    beforeService: '工程前',
    afterService: '工程後',

    downloadFile: '下載PDF檔案',
    serviceFee: '服務收費：',

    FeedbackPage: {
      contactInfo: '聯絡資料',
      nickname: '您的名字',
      enterNickname: '請輸入您的名字',
      email: '您的電郵',
      enterEmail: '請輸入您的電郵',
      feedback: '意見反饋',
      enterFeedback: '在此輸入您的意見',
      feedbackSubmitted: '意見提交成功！',
      phone: '您的手機號碼',
      enterPhone: '請輸入您的手機號碼',
    },
    LoginPage: {
      login: '登入',
      password: '密碼',
      loginEmail: '手機號碼 / 電郵地址',
      loginWithVerificationCode: '手機驗證碼登入',
      scanLocationQRCode: '掃描地盤二維碼註冊',
      sendVerificationCode: '發送驗證碼',
    },
    RegisterPage: {
      registerAccount: '註冊帳號',
      loginEmail: '登入電郵*',
      password: '密碼*',
      confirmPassword: '確認密碼*',
      register: '註冊',
      inconsistentPasswordMsg: '兩次輸入的密碼不一致，請重新輸入。',
      enterEmailAndPassword: '請輸入電郵及密碼。',
      phone: '手機號碼',
      email: '電郵地址 (可選填)',
      enterPhoneAndPassword: '請輸入手機號碼及密碼。',
      enterValidHKMobileNumber: '請輸入有效的香港手機號碼 (8位數字)',
      verifyingMobileNumber: '驗證閣下的手機號碼',
      verifyMobileNumberInstruction: '請輸入短訊驗證碼，驗證碼已發送至您的手機',
      verificationCode: '短訊驗證碼',
      invalidVerificationCode: '無效短訊驗證碼！',
      verify: '驗證',
      phoneRegistered: '此手機號碼已被註冊'
    }
  },
  en: {
    done: 'Done',
    loginOrRegister: 'Login / Register',
    next: 'Next',
    loginPhone: 'Your Mobile Number',
    finish: 'Done',
    completeRegistration: 'Complete your registration',
    nickname: 'Your name',
    loadingMore: 'Loading...',

    deleteAccount: 'Delete Account',
    confirmDeleteAccountMsg: 'Confirm delete this account and all related personal information?',
    accountDeleted: 'Account deleted.',
    details: 'Details',

    // Devices
    lastOnlineTime: 'Last seen: ',
    lastUpdated: 'Last Updated: ',
    'yellow': 'Amber',
    'red': 'Red',
    'black': 'Black',

    // Check Location
    overview: 'Overview',
    addNewEntity: 'Add new',
    updateEntity: 'Update',
    target: 'Target',
    addNewCheckLocation: 'Add new check location',
    updateCheckLocation: 'Update check location',
    confirmMove: 'Confirm move?',
    showPhotos: 'Photos',
    hidePhotos: 'Photos',
    showPhotoCodeLabels: 'Photo Codes',
    showPhotoStats: 'Photo Statistics',
    showBackgroundImg: 'Show Bg Img',

    // Camera Prompt
    photo: 'Photo',
    fromPhotos: 'From Photos',
    takePicture: 'Take Picture',
    successUpdatePhoto: 'Photo updated successfully!',
    change: 'Change',
    editInfo: 'Edit',
    complete: 'Complete',
    confirmLeave: 'Confirm leave?',
    
    shop: 'Shop',
    cart: 'Cart',
    checkout: 'Checkout',
    forum: 'Forum',
    profile: 'Profile',
    services: 'Services',

    sale: 'SALE',
    soldOut: 'SOLD OUT',

    yes: 'Yes',
    no: 'No',
    back: 'Back',
    confirm: 'Confirm',
    cancel: 'Cancel',
    all: 'All',

    remark: 'Remark',
    submit: 'Submit',
    
    buyNow: 'Buy Now',
    addToCart: 'Add to Cart',
    addToLikedItems: 'Add to My List',
    removeFromLikedItems: 'Remove from My List',
    enquiry: 'Enquiry',
    product: 'Product',

    successUpdateLikedItems: 'List of liked items updated successfully!',
    successAddedToCart: 'Item added to cart successfully!',

    errorHeader: 'ERROR!',

    // bookings
    pending: 'Pending',
    confirmed: 'Confirmed',
    ongoing: 'Ongoing',
    completed: 'Completed',
    cancelled: 'Cancelled',
    minutes: 'minutes',
    bookingDetails: 'Booking Details',
    bookedServices: 'Booked Services: ',
    bookingQRCode: 'Booking QR Code',
    changeBookingTime: 'Change Booking Time',
    cancelBooking: 'Cancel Booking',
    confirmCancel: 'Confirm Cancel',
    confirmCancelBooking: 'Confirm cancel this booking?',
    bookingCancelled: 'Booking cancelled.',
    newBooking: 'New Booking',
    timesPurchased: 'Times Purchased: ',
    timesUsed: 'Used: ',
    timesRemaining: 'Remaining: ',
    bookingDate: 'Booking Date',
    selectBookingDate: 'Please select the booking date',
    bookingTime: 'Booking Time',
    selectBookingTime: 'Please select the booking time',
    updateBooking: 'Edit Booking',
    createBooking: 'Create Booking',
    successCreateBooking: 'Booking created successfully!',
    successUpdateBooking: 'Booking updated successfully!',
    beforeTreatment: 'Before Treatment',
    afterTreatment: 'After Treatment',

    deliveryDistrict: 'Delivery District: ',
    deliveryAddress: 'Delivery Address: ',
    customerName: 'Customer Name: ',
    contactPhone: 'Contact Phone: ',
    customerEmail: 'Email Address: ',

    CheckoutPage: {
      yourOrder: 'Your Order',
      orderRemark: 'Order Remark',
      specialIntructions: 'Special Intructions to the Seller',
      deliveryInfo: 'Delivery Information',
      selectDistrict: 'Select the delivery district',
      enterDeliveryAddress: 'Enter the delivery address',
      enterCustomerName: 'Enter your name',
      enterContactPhone: 'Enter the contact phone',
      enterCustomerEmail: 'Enter the email address',
      contactInfo: 'Contact Information',
      paymentInfo: 'Payment Information',
      confirmPayment: 'Submit',
      paymentProof: 'Payment Proof',
    },
    CartPage: {
      lineTotal: 'Total: ',
      orderTotal: 'Total: ',
      checkout: 'Checkout',
      shopNow: 'Shop Now',
      emptyCart: 'Your shopping cart is empty',
      removeCartItem: 'Remove Cart Item',
      confirmRemoveCartItem: 'Confirm removing this item from cart?',
    },

    HomePage: {
      featuredProducts: 'Featured Products',
      recommendedForYou: 'Recommended For You',
      latestProducts: 'Latest Products',
    },

    OrderPage: {
      order: 'Order',
      orderStatus: 'Order Status',
      orderItems: 'Order Items',
      lineTotal: 'Line Total: ',
      orderTotal: 'Order Total: ',
      deliveryInfo: 'Delivery Information',
      orderNumber: 'Order ID: ',
      total: 'Total: ',
    },

    PostPage: {
      newPost: 'New Post',
      lastUpdated: 'Last Updated: ',
      numOfComments: ' Comments',
      newComment: 'New Comment',
      commentBtnText: 'COMMENT',
      editPost: 'Edit Post',
      postTitle: 'Post Title',
      postContent: 'Post Content',
      postImage: 'Image',
      updatePost: 'Update Post',
      publishPost: 'Publish Post',
      successPublishPost: 'Post published successfully!',
      successUpdatePost: 'Post updated successfully!',
      confirmDelete: 'Confirm Delete',
      confirmDeletePost: 'Confirm delete this post?',
      deletePost: 'Delete Post',
      postDeleted: 'Post deleted.',
    },

    ProductPage: {
      numOfCustomerReviews: ' Customer Reviews',
      newReview: 'Write a review',
      successPublishProductReview: 'Review published successfully!',
      reviewRating: 'Rating',
      reviewContent: 'Review',
      publishReview: 'Publish Review',
    },

    ForgotPasswordPage: {
      loginEmail: 'Login Email',
      forgotPassword: 'Forgot Password',
      sendResetPasswordEmail: 'Send Reset Password Email',
      resetPswEmailSentTo: 'Email has been sent to',
      checkEmailReset: 'Please reset the password by clicking the link in the email.',
      backToLogin: 'Back to Login',
      enterLoginEmail: 'Please enter the login email',
    },

    ThankYouPage: {
      thankYouForYourOrder: 'Thank you for your order!',
      description: "You will soon receive an order confirmation notification",
      backToHomePage: 'Back to Home',
    },

    UserProfilePage: {
      changeProfilePhoto: 'Change Profile Photo',
      successChangeProfilePhoto: 'Profile photo changed successfully!',
      editPersonalInfo: 'Edit Personal Information',
      changePassword: 'Change Password',
      oldPassword: 'Old Password',
      newPassword: 'New Password',
      newPasswordConfirm: 'Confirm New Password',
      inconsistentNewPasswordMsg: 'New passwords do not match. Please enter again.',
      wrongOldPassword: 'The old password is wrong. Please enter again.',
      successUpdatePassword: 'Password updated successfully!',
      enterAllFields: 'Please enter all fields.',
      myOrders: 'My Orders',
      myPosts: 'My Posts',
      likedItems: 'Liked Items',
      language: 'Language',
      darkTheme: 'Dark Theme',
      termsAndConditions: 'Privacy Policy & Terms and Conditions',
      feedback: 'Feedback',
      logout: 'Log Out',
      confirmLogout: 'Confirm log out?',
      lastName: 'Last Name',
      firstName: 'First Name',
      emailAddress: 'Email Address',
      phone: 'Phone',
      save: 'Save',
      name: 'Name',
    },

    // add extra en translations below
    BookingStatus: {
      pending: 'Pending',
      visitConfirmed: 'Visit Confirmed',
      visited: 'Visited',
      quoted: 'Quoted',
      quoteConfirmed: 'Quotation Confirmed',
      workConfirmed: 'Work Confirmed',
      ongoing: 'Work In Progress',
      workDone: 'Work Done',
      pendingPayment: 'Pending Payment',
      paid: 'Paid',
      completed: 'Completed',
      cancelled: 'Cancelled',
    },

    submitted: 'Submitted',
    notSubmitted: 'Not Submitted',
    surveySubmitted: 'Survey submitted successfully!',

    backToMyBookings: 'Back to my bookings',
    thankYouForYourPayment: 'Thank you for your payment!',
    thankYouPaymentDescription: 'You will soon receive the payment confirmation notification and receipt.',
    unknownErrorOccurred: 'Some error occurred. Please try again.',
    deposit: 'Deposit',
    payDeposit: 'Make a Deposit',
    finalPayment: 'Final Payment',
    payNow: 'Pay Now',
    customerNote: 'Customer Note',
    showQuotation: 'Show Quotation',
    bookingQuotation: 'Work Quotation',
    confirmedQuotationMsg: 'Quotation Confirmed. We will contact you for the work details.',
    bookingReceipt: 'Work Receipt',
    showReceipt: 'Show Work Receipt',
    signConfirmQuote: 'Please sign to confirm the quote',
    signInBox: 'Please sign in the box',
    resetSignature: 'Reset Signature',
    signInsideBlackBox: 'Please sign inside the black box',

    delete: 'Delete',
    functionOnlyAvailableOnMobileApp: 'Please download "WINS" Mobile App to use this function.',
    enablePermissionInAppSettings: 'If you want to grant permission for using your camera, enable it in the app settings.',

    or: 'or',
    loginInformation: 'Login Account Information',
    apartmentInformation: 'Apartment Information',
    registeringAccount: 'Setting up account...',
    successUpdatePersonalInfo: 'Personal information updated successfully.',
    clashEmailAddress: 'The email address is already in use by another account.',
    wrongPassword: 'Login information is incorrect.',
    editMobileNumber: 'Edit Mobile Number',

    aboutBaily: 'About Baily',
    orgChart: 'Organization Chart',
    scopeOfServices: 'Scope of Services',
    relatedDocuments: 'Related Documents',
    contactUs: 'Contact Us',
    companyAddress: 'Address: ',
    companyEmail: 'Email: ',
    companyPhone: 'Telephone: ',
    fax: 'Fax: ',
    專業資格及牌照: 'Professional Qualifications & Licenses',
    屋宇署註冊證書: 'Certificate of Registration of General Building Contractor',
    電業承辦商註冊證書: 'Certificate of Registration of Electrical Contractor',
    註冊消防裝置承辦商: 'Registered Fire Service Installation Contractor',
    ISO認證副本: 'Certificate of ISO qualification',
    其他相關人員證書及牌照: 'Certificates and licenses of other related personnel',
    無涉及任何法律訴訟信件: 'Does not involve any written correspondence leading to legal action',
    銀行發出之存款證明文件: 'Financial Reference Letter',
    主要管理人員資歷及證書: 'Qualification & Certificates of major management staff',
    推薦信: 'Recommendation Letters',

    days: 'days',
    remainingDays: 'Remaining Days: ',

    scanLocationQRCode: 'Scan Location QR Code',
    scanQRCodeAddLocation: 'Scan QR code to add the location',
    scanningQRCode: 'Scanning QR Code ...',
    search: 'Search',
    unknown: 'Unknown',
    project: 'Project',
    projects: 'Projects',
    projectAddress: 'Address: ',
    projectFee: 'Project Fee: ',
    projectStartDate: 'Start Date: ',
    projectEndDate: 'End Date: ',
    notifications: 'Notifications',
    projectNotifications: 'Project Notifications',
    residentFeedback: 'Resident Feedback',
    projectStatus: {
      inProgress: 'In Progress',
      completed: 'Completed',
    },
    subscribedNotifications: 'Notifications',
    receiveNotifications: 'Receive Notifications',
    stopReceivingNotifications: 'Stop Receiving Notifications',
    sendFeedback: 'Send Feedback',
    photoOptional: 'Supporting Photos (if any)',
    projectNotFound: 'Project Not Found!',

    latestProjects: 'Latest Projects',
    inProgressProjects: 'In Progress Projects',
    completedProjects: 'Completed Projects',

    待開工: 'Pending',
    工程中: 'In Progress',
    已完工: 'Completed',
    已取消: 'Cancelled',
    beforeWorkPhotos: 'Before Work Photos',
    afterWorkPhotos: 'After Work Photos',
    workPhotos: 'Work Photos',

    locationInputWarningMsg: 'Please ensure that the floor and unit are correct to avoid receiving wrong notifications.',
    newUserLocation: 'New User Location',
    userLocation: 'User Location',
    buildingOrLocation: 'Building / Location',
    floor: 'Floor',
    unit: 'Unit',
    業主: 'Tenant',
    住戶: 'Resident',
    其他: 'Other',
    save: 'Save',
    deleteUserLocation: 'Delete Location',
    confirmDelete: 'Confirm Delete',
    confirmDeleteUserLocation: 'Confirm delete this location',
    successDeleteUserLocation: 'User location deleted successfully!',
    successUpdateUserLocation: 'User location updated successfully!',
    selectBuildingOrLocation: 'Please select building / location',
    addBuildingOrLocation: 'Add Location',
    myLocations: 'My Locations',
    myLocation: 'My Location',
    involvingProjects: 'Projects I work on',

    description: 'Description',
    address: 'Address',
    selectOrEnterAddress: 'Select / Enter Your Address',

    clientPhotos: 'Photos provided by the client',
    beforeService: 'Before Work',
    afterService: 'After Work',

    downloadFile: 'Download PDF File',
    serviceFee: 'Service Fee: ',

    FeedbackPage: {
      contactInfo: 'Contact Information',
      nickname: 'Your name',
      enterNickname: 'Enter your name',
      email: 'Your email',
      enterEmail: 'Enter your email',
      feedback: 'Feedback',
      enterFeedback: 'Enter your feedback here',
      feedbackSubmitted: 'Feedback submitted successfully!',
      phone: 'Your phone',
      enterPhone: 'Enter your phone',
    },
    LoginPage: {
      login: 'Login',
      password: 'Password',
      loginEmail: 'Mobile Number / Email',
      loginWithVerificationCode: 'Log in with SMS Verification Code',
      scanLocationQRCode: 'Register with QR Code',
      sendVerificationCode: 'Send Verification Code',
    },
    RegisterPage: {
      registerAccount: 'Register',
      loginEmail: 'Login Email*',
      password: 'Password*',
      confirmPassword: 'Confirm Password*',
      register: 'Register',
      enterEmailAndPassword: 'Please enter both the email address and password.',
      inconsistentPasswordMsg: 'Passwords do not match. Please enter again.',
      phone: 'Mobile Number',
      email: 'Email (optional)',
      enterPhoneAndPassword: 'Please enter both the mobile number and password.',
      enterValidHKMobileNumber: 'Please enter a valid Hong Kong mobile number (8 digits)',
      verifyingMobileNumber: 'Verify your mobile number',
      verifyMobileNumberInstruction: 'Please enter the verification code that was sent to your mobile device',
      verificationCode: 'Verification Code',
      invalidVerificationCode: 'Invalid verification code!',
      verify: 'Verify',
      phoneRegistered: 'This phone has already been registered'
    }
  }
}